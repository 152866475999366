import { whitelist } from '@/constants/whitelist';
import { page } from '@/plugins/customUrlScheme';
import { useGtm } from '@gtm-support/vue2-gtm';

export const useContentLink = () => {
  const gtm = useGtm();
  const prdOnlineStoreDomain = 'https://store.ucc.co.jp';
  const envOnlineStoreDomain = process.env.VUE_APP_EC_BASE_URL;
  const onlineStoreDomains = [prdOnlineStoreDomain, envOnlineStoreDomain];

  const appLinkRegex = new RegExp(/coffee-creation-app:\/\//);

  const isAppLink = (url) => appLinkRegex.test(url);

  const isOnlineStoreLink = (url_) => {
    if (!url_) return false;
    const url = url_.replace(/\/$/, '');
    const validUrl = (item) => url.match(new RegExp(item.replace(/\/$/, '')));
    return onlineStoreDomains.some(validUrl);
  };

  const serviceGroupList = whitelist;

  const isServiceGroupLink = (url_) => {
    if (!url_) return false;
    const url = url_.replace(/\/$/, '');
    const validUrl = (item) => url.match(new RegExp(item.replace(/\/$/, '')));
    return serviceGroupList.some(validUrl);
  };

  /**
   * URLの種類によって処理を切り替える
   * @param {string} url
   */
  const getContentLink = (url, eventId) => {
    if (!eventId && url === null) return false;

    /* eslint-disable no-console */
    console.debug('Clicked url :>> ', url);
    console.debug('isAppLink(url) :>> ', isAppLink(url));
    console.debug('isOnlineStoreLink(url) :>> ', isOnlineStoreLink(url));
    console.debug('isServiceGroupLink(url) :>> ', isServiceGroupLink(url));
    /* eslint-enable no-console */

    const isEvent = !!eventId;

    let value = '';
    if (isEvent) {
      // 参加する（イベント）詳細
      value = `coffee-creation-app://page/detail/?url=/content/join/detail/${eventId}`;
    } else if (isOnlineStoreLink(url)) {
      // オンラインストア
      value = page('ec', 'url=' + url);
    } else if (isAppLink(url)) {
      // アプリ内遷移
      value = url;
    } else if (isServiceGroupLink(url)) {
      // ホワイトリスト内
      value = page('standalone', 'url=' + url);
    } else {
      // 外部ブラウザ
      value = page('external', 'url=' + url);
    }
    return value;
  };

  const sendToGaClickLink = (event, contentUrl) => {
    gtm.trackEvent({
      event,
      contentUrl
    });
  };

  return {
    appLinkRegex,
    isAppLink,
    isOnlineStoreLink,
    serviceGroupList,
    isServiceGroupLink,
    getContentLink,
    gtm,
    sendToGaClickLink
  };
};
