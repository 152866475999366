var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-content p-content-read" },
    [
      _vm.features && _vm.features.length
        ? _c(
            "div",
            { staticClass: "p-content__section-l c-section" },
            [
              _c(
                "Carousel",
                _vm._l(_vm.features, function (item) {
                  return _c("ContentCarouselCard", {
                    key: item.index,
                    staticClass: "swiper-slide",
                    attrs: {
                      src: item["image_url"],
                      title: item["title"],
                      subtitle: item["subtitle"],
                      tag: item["tag"],
                      reward: item["reward"],
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onClickContentLink(
                          item["link_url"],
                          item["event_id"]
                        )
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contents && _vm.contents.length
        ? _vm._l(_vm.contents, function (content) {
            return _c("div", { key: content.index, staticClass: "u-mb24" }, [
              _c("div", { staticClass: "p-content__section-m c-section" }, [
                _c(
                  "div",
                  { staticClass: "c-cards c-cards--2cols" },
                  _vm._l(_vm.middleContents(content), function (item) {
                    return _c("Content2ColCard", {
                      key: item["id"],
                      attrs: {
                        src: item["image_url"],
                        title: item["title"],
                        subtitle: item["media"],
                        tag: item["tag"],
                        reward: item["reward"],
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickContentLink(
                            item["link_url"],
                            item["event_id"]
                          )
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "c-cards c-cards--tiled" },
                _vm._l(_vm.smallContents(content), function (item) {
                  return _c("ContentTileCard", {
                    key: item["id"],
                    attrs: {
                      src: item["image_url"],
                      title: item["title"],
                      subtitle: item["media"],
                      tag: item["tag"],
                      reward: item["reward"],
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onClickContentLink(
                          item["link_url"],
                          item["event_id"]
                        )
                      },
                    },
                  })
                }),
                1
              ),
            ])
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }