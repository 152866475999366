<template>
  <div class="p-content p-content-read">
    <!-- <div v-if="medias && medias.length" class="c-section c-section--mb20">
      <ContentRssMedia :contents="medias" />
    </div> -->

    <div
      v-if="features && features.length"
      class="p-content__section-l c-section">
      <Carousel>
        <ContentCarouselCard
          v-for="item in features"
          :key="item.index"
          :src="item['image_url']"
          :title="item['title']"
          :subtitle="item['subtitle']"
          :tag="item['tag']"
          :reward="item['reward']"
          class="swiper-slide"
          @click="onClickContentLink(item['link_url'], item['event_id'])" />
      </Carousel>
    </div>

    <template v-if="contents && contents.length">
      <div class="u-mb24" v-for="content in contents" :key="content.index">
        <div class="p-content__section-m c-section">
          <div class="c-cards c-cards--2cols">
            <Content2ColCard
              v-for="item in middleContents(content)"
              :key="item['id']"
              :src="item['image_url']"
              :title="item['title']"
              :subtitle="item['media']"
              :tag="item['tag']"
              :reward="item['reward']"
              @click="onClickContentLink(item['link_url'], item['event_id'])" />
          </div>
        </div>

        <div class="c-cards c-cards--tiled">
          <ContentTileCard
            v-for="item in smallContents(content)"
            :key="item['id']"
            :src="item['image_url']"
            :title="item['title']"
            :subtitle="item['media']"
            :tag="item['tag']"
            :reward="item['reward']"
            @click="onClickContentLink(item['link_url'], item['event_id'])" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useContentLink } from '@/composables/useContentLink';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount
} from '@vue/composition-api';
export default defineComponent({
  components: {
    // ContentRssMedia: () => import('@/components/content/ContentRssMedia.vue'),
    ContentCarouselCard: () =>
      import('@/components/content/ContentCarouselCard.vue'),

    Content2ColCard: () => import('@/components/content/Content2ColCard.vue'),
    ContentTileCard: () => import('@/components/content/ContentTileCard.vue')
  },

  setup: (props, context) => {
    const { getContentLink, isAppLink, sendToGaClickLink } = useContentLink();
    const medias = ref(undefined);
    const contents = ref([]);
    const features = ref(undefined);
    const nextPageUrl = ref(null);
    const scrolledToBottom = ref(false);

    // NOTE: インセンティブの付与は保留
    // const plusMileage = ref(0);
    // const isStageUp = ref(false);
    // const nowMileage = ref(0);
    // const hasPresent = ref(false);
    // const stageNameBefore = ref(null);
    // const stageNameAfter = ref(null);
    // const stageNameNext = ref(null);

    // const fetchMediasList = async () => {
    //   try {
    //     context.root.$_loading_start();

    //     const { data } = await context.root
    //       .$repositories('contentReadMedia')
    //       .get();
    //     medias.value = data;
    //   } catch {
    //     window.location.href = context.root.$systemDialog(
    //       SystemDialogMessage.ERROR_COMMON
    //     );
    //   } finally {
    //     context.root.$_loading_stop();
    //   }
    // };

    const fetchFeaturesList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('contentReadFeature')
          .get();
        features.value = data.data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const fetchContentsList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('contentRead')
          .get(context.root.$utilities.getUrlSearch(nextPageUrl.value));
        contents.value.push(data.data);
        nextPageUrl.value = data.next_page_url;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * 画面下部までスクロールしたときに次のセクション分のコンテンツを取得する
     */
    const scroll = () => {
      const isBottomOfWindow =
        Math.ceil(window.pageYOffset) + window.innerHeight >=
        document.documentElement.offsetHeight;

      if (isBottomOfWindow) {
        scrolledToBottom.value = true;
        loadMore();
      }
    };

    const loadMore = async () => {
      if (context.root.$_loading_state || !nextPageUrl.value) return;
      await fetchContentsList();
    };

    /**
     * コンテンツ押下時の処理
     * @param {string} contentUrl
     * @param {number} eventId
     */
    const onClickContentLink = async (contentUrl, eventId) => {
      // NOTE: GTMのトラッキング（アプリ内の遷移は除外）
      if (!isAppLink(contentUrl)) {
        await sendToGaClickLink('click_content_read_external_link', contentUrl);
      }

      // NOTE: インセンティブの付与は保留
      // const hasAlreadyRead = !eventId && !!contentUrl && contentUrl !== null;
      // if (hasAlreadyRead) {
      //   await alreadyRead(contentUrl);
      // }

      const url = await getContentLink(contentUrl, eventId);
      if (url) window.location.href = url;

      // NOTE: インセンティブの付与は保留
      // if (hasAlreadyRead) {
      //   context.root.$contentList.presentIncentive(
      //     plusMileage.value,
      //     isStageUp.value,
      //     nowMileage.value,
      //     hasPresent.value,
      //     stageNameBefore.value,
      //     stageNameAfter.value,
      //     stageNameNext.value
      //   );
      // }
    };

    /**
     * 既読処理
     * @param {string} contentUrl
     */
    // const alreadyRead = async (contentUrl) => {
    //   try {
    //     context.root.$_loading_start();

    //     const { data } = await context.root
    //       .$repositories('contentAlreadyRead')
    //       .post({
    //         url: contentUrl
    //       });

    //     plusMileage.value = data?.mileage['plus_mileage'];
    //     isStageUp.value = data?.mileage['rank_up'];
    //     nowMileage.value = data?.mileage['now_mileage'];
    //     hasPresent.value = !!data?.present[0];
    //     stageNameBefore.value = data.mileage.stage?.before.name;
    //     stageNameAfter.value = data.mileage.stage?.after.name;
    //     stageNameNext.value = data.mileage.stage?.next.name;
    //   } catch {
    //     // noop
    //   } finally {
    //     context.root.$_loading_stop();
    //   }
    // };

    /**
     * contentsの4件目までを取得
     * @param {object}
     */
    const middleContents = (values) => values.slice(0, 4);

    /**
     * contentsの5件目以降を取得
     * @param {object}
     */
    const smallContents = (values) => {
      if (values.length < 5) return;
      return values.slice(5);
    };

    onBeforeMount(async () => {
      // await fetchMediasList();
      await fetchFeaturesList();
      await fetchContentsList();
      window.addEventListener('scroll', scroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scroll);
    });

    return {
      medias,
      contents,
      features,
      middleContents,
      smallContents,
      onClickContentLink
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.p-content-read {
  .p-content__section-l,
  .p-content__section-m {
    .c-card__bottom {
      min-height: 28px;
    }
  }
}

.p-content-read__media__title {
  @include mixin.clamp(2);
}
</style>
